<template>
    <div class="reset-password bg-light text-primary font-weight-medium">
        <img
            v-if="step === 0"
            src="@/assets/icons/left_arrow_icon.svg"
            :style="{ justifySelf: 'start' }"
            class="icon-lg"
            @click="$router.replace('/login')"
        />
        <img
            v-else
            src="@/assets/cross.svg"
            class="icon-md"
            :style="{ justifySelf: 'end' }"
            @click="$router.replace('/login')"
        />
        <img src="@/assets/logo_primary.svg" />
        <h2>Reset password</h2>
        <form class="gb-form gb-form--centered" @submit.prevent="doPasswordReset">
            <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
                <label v-if="step === 0" for="email">Your email</label>
                <label v-else for="email">We've just sent a reset link to this email:</label>
                <BaseInput
                    id="email"
                    v-model="email"
                    type="email"
                    required
                    text-align="center"
                    :disabled="step !== 0"
                />
            </fieldset>

            <small v-if="showError" class="text--red">Something went wrong!</small>
            <small v-else-if="step === 0">
                We'll send a reset-link to your email
            </small>
            <small v-else-if="step === 1">
                Open the mail and follow the steps
            </small>
            <Button
                v-if="step === 0"
                label="Send me a link"
                primary
                fluid
                type="submit"
                :disabled="loading"
            />
            <Button v-else label="Open mail" primary fluid disabled />
        </form>
    </div>
</template>

<script>
import logger from '@/utils/logger.js'

export default {
    name: 'ResetPassword',
    inject: ['resetUserPassword'],
    data() {
        return {
            step: 0,
            loading: false,
            email: '',
            showError: false
        }
    },
    methods: {
        incrementStep() {
            this.step++
        },
        async doPasswordReset() {
            try {
                this.loading = true
                this.showError = false
                const result = await this.resetUserPassword(this.email)
                logger.log(result)

                // IF successful
                this.incrementStep()
            } catch (error) {
                this.showError = true
                throw new Error(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.reset-password {
    min-height: 100vh;
    padding: 1rem;
    display: grid;
    align-content: start;
    justify-items: center;
    row-gap: 1rem;
    padding-top: 4rem;

    form {
        max-width: 260px;
        box-sizing: content-box;
        fieldset {
            text-align: center;
        }
    }
}
</style>
